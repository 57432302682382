.MuiButton-root {
    border-radius: 50px !important;
  }

.character {
  border-radius: 8px;
}

.character--inactive {
  background-color: #cfcbc4;
  border-color: #cfcbc4;
}

.character--selected {
  outline-color: #588157;
}